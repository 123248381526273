"use client";

import { ReactNode, useEffect } from "react";
import Analytics from "@/components/common/Analytics";
import Styles from "@/components/common/Styles";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthContextProvider } from "@/contexts/common/AuthContextProvider";
import { PHProvider, PostHogPageview } from "./providers";
import { AlertContextProvider } from "@/hooks/useAlert";
import { NextUIProvider } from "@nextui-org/react";
import { NavContainerProvider } from "@/contexts/common/NavContainerProvider";
import Zendesk from "@/components/ZenDesk";
import ReleaseNoteInitialization from "./releaseNotesInitialization";
import { ProfileContextProvider } from "@/hooks/useProfile";
import UserManagementModal from "@/components/common/UserManagementModal";
import React from "react";
import "@/i18n/config";
import { useTranslation } from "react-i18next";
import { Themes } from "@/enums/themes";
import { GetTheme } from "@/utils/common/theme";

export default function RootLayout({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation();

  const [show, setShow] = React.useState(false);
  const [language, setLanguage] = React.useState("en");
  const [theme, setTheme] = React.useState<Themes>(Themes.GAIL);

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    handleLanguageChange(navigator.language);
    setTheme(GetTheme(window.location.host));
  }, []);

  return (
    <html lang={language} data-theme={theme}>
      <head>
        <script
          src="https://kit.fontawesome.com/26f92a71e5.js"
          crossOrigin="anonymous"
          async
        ></script>
        {theme === Themes.GAIL && <link rel="icon" href="/favicon.ico" />}
        {theme === Themes.DESOL && (
          <link rel="icon" href="/icons/desol_icon.png" />
        )}
      </head>
      <PHProvider>
        <body>
          <ReleaseNoteInitialization />
          <PostHogPageview />
          <Analytics />
          <Styles />
          <Auth0Provider
            domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN!}
            clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!}
            useRefreshTokens
            cacheLocation="localstorage"
            cookieDomain={process.env.NEXT_PUBLIC_AUTH0_COOKIE_DOMAIN!}
          >
            <AuthContextProvider>
              <NextUIProvider>
                <ProfileContextProvider setLanguage={handleLanguageChange}>
                  <NavContainerProvider setShowUserManagement={setShow}>
                    <AlertContextProvider isAdmin={false}>
                      <Zendesk />
                      <UserManagementModal show={show} setShow={setShow} />
                      {children}
                    </AlertContextProvider>
                  </NavContainerProvider>
                </ProfileContextProvider>
              </NextUIProvider>
            </AuthContextProvider>
          </Auth0Provider>
        </body>
      </PHProvider>
    </html>
  );
}
